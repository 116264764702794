var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "search"
  }, [_c('Card', [_c('Row', {
    staticClass: "operation",
    attrs: {
      "align": "middle",
      "justify": "space-between"
    }
  }, [_c('div', [_c('Button', {
    attrs: {
      "type": "primary",
      "icon": "md-add"
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("添加")]), _c('Button', {
    attrs: {
      "icon": "md-trash"
    },
    on: {
      "click": _vm.delAll
    }
  }, [_vm._v("批量删除")]), _c('Button', {
    attrs: {
      "icon": "md-refresh"
    },
    on: {
      "click": _vm.getDataList
    }
  }, [_vm._v("刷新")]), _c('Button', {
    attrs: {
      "type": "dashed"
    },
    on: {
      "click": function ($event) {
        _vm.openTip = !_vm.openTip;
      }
    }
  }, [_vm._v(_vm._s(_vm.openTip ? "关闭提示" : "开启提示"))])], 1), _c('Poptip', {
    attrs: {
      "placement": "bottom-end",
      "width": "160"
    }
  }, [_c('Button', {
    attrs: {
      "shape": "circle",
      "icon": "md-apps"
    }
  }), _c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('CheckboxGroup', {
    on: {
      "on-change": _vm.changeColumns
    },
    model: {
      value: _vm.columnSettings,
      callback: function ($$v) {
        _vm.columnSettings = $$v;
      },
      expression: "columnSettings"
    }
  }, _vm._l(_vm.columns, function (column, i) {
    return _c('div', {
      key: i,
      staticStyle: {
        "margin": "3px 0"
      }
    }, [column.key && column.key.indexOf(_vm.whiteColumns) <= -1 ? _c('Checkbox', {
      attrs: {
        "label": column.key,
        "disabled": column.disabled
      }
    }, [_c('span', [_vm._v(_vm._s(column.title))])]) : _vm._e()], 1);
  }), 0)], 1)], 1)], 1), _c('Alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.openTip,
      expression: "openTip"
    }],
    attrs: {
      "show-icon": ""
    }
  }, [_vm._v(" 已选择 "), _c('span', {
    staticClass: "select-count"
  }, [_vm._v(_vm._s(_vm.selectList.length))]), _vm._v(" 项 "), _c('a', {
    staticClass: "select-clear",
    on: {
      "click": _vm.clearSelectAll
    }
  }, [_vm._v("清空")]), _vm._v(" 这里还可以做一些数据统计显示 "), _c('span', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("点击右上角按钮配置动态列↑")])]), _c('Table', {
    ref: "table",
    attrs: {
      "loading": _vm.loading,
      "border": "",
      "columns": _vm.dynamicColums,
      "data": _vm.data,
      "sortable": "custom"
    },
    on: {
      "on-sort-change": _vm.changeSort,
      "on-selection-change": _vm.changeSelect
    }
  }), _c('Row', {
    staticClass: "page",
    attrs: {
      "type": "flex",
      "justify": "end"
    }
  }, [_c('Page', {
    attrs: {
      "current": _vm.searchForm.pageNumber,
      "total": _vm.total,
      "page-size": _vm.searchForm.pageSize,
      "page-size-opts": [10, 20, 50],
      "size": "small",
      "show-total": "",
      "show-elevator": "",
      "show-sizer": ""
    },
    on: {
      "on-change": _vm.changePage,
      "on-page-size-change": _vm.changePageSize
    }
  })], 1)], 1), _c('Modal', {
    attrs: {
      "title": _vm.modalTitle,
      "mask-closable": false,
      "width": 500
    },
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v;
      },
      expression: "modalVisible"
    }
  }, [_c('Form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "label-width": 80,
      "rules": _vm.formValidate
    }
  }, [_c('FormItem', {
    attrs: {
      "label": "名称",
      "prop": "name"
    }
  }, [_c('Input', {
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    attrs: {
      "type": "text"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('Button', {
    attrs: {
      "type": "primary",
      "loading": _vm.submitLoading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };